.header_holder
{
	position: relative;
	
	display: flex;
	
	padding: 2.5rem 1rem 0 1rem;
}

.logo
{
	width: 135px;
}

.user_info_items
{
	display: flex;
	align-items: center;
	
	padding: 0 1.5rem;
}

.logo_hold
{
	align-self: center;
}

.head_left
{
	display: flex;
}


.attende
{
	width: 55px;
	height: 55px;
	
	border: 2px solid #BD00FF;
	border-radius: 10px;
	padding: 5px;
}

.user_info
{
	margin-left: 20px;
}

.user_name
{
	font-size: 24px;
}

.user_sessions
{
	font-size: 14px;
	color: #9b9b9b;
	line-height: 20px;
}

.settings_hold
{
	display: flex;
	
	flex: 0 0 67%;
	justify-content: flex-end;
}

.settings_icon
{
	position: relative;
}

.settings_icon::before {
	content: "";
	position: absolute;
	height: 90%;
	width: 1px;
	background-color: #474747;
	left: -30px;
	transform: translateY(-50%);
	top: 50%;
}
