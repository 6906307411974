.songs_list_item
{
	position: relative;
	display: flex;
	
	padding: 15px 0px 15px 45px;
	
	background-image: url('../assets/images/play.svg');
	background-repeat: no-repeat;
	background-size: 15px auto;
	background-position: 15px center;
}

.songs_list_item.active
{
	background-color: #313131;
	
	background-image: url('../assets/images/play_bottom.svg');
	
	color: #b0b9b0;
}

.about_song
{
	width: 25%;
}



.about_song.song11
{ 
	width: 25%;
	
	word-wrap: break-word;
}

.about_song.song2
{ 
	width: 25%; 
	padding-left: 40px;
}

.about_song.description
{
	width: 40%;
}

.song_main_holder
{
	padding: 20px 0px;
	
	cursor: pointer;
} 

.playlist_songs
{
	position: relative;
	top: 5px;
	height: 0px;
	
	padding: 0px 30px 0px 45px;
	
	background-color: #212121;
	
	transition: all 0.5s;
	
	overflow: hidden;
}

.playlist_songs.active
{
	height: auto;
	
	overflow: auto;
}

.pl_song_heder
{
	display: flex;
	
	padding: 20px 0px;
	border-bottom: 1px solid #000;
	
}
.s1
{
	width: 33.3%;
	
	align-self: center;
}

.s1 div
{
	padding: 0 30px 0 10px;
	
	line-height: 170%
}

.s2
{
	width: 33.3%;
	
	align-self: center;
}

.plist_song_list
{
	position: relative;
	
	padding: 10px 0px;
	border-top: 1px solid #676767;
	
	color: #b0b9b0;
}

.psong_item
{
	display: flex;
	
	padding: 10px 0px;
	
	background-image: url('../assets/images/order.png');
	background-repeat: no-repeat;
	background-size: 17px auto;
	background-position: 99% center;
	
	user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}

.plist_main_hold
{
	transition: all 0.2 ease-in-out;
}

.ondrag
{
	position: relative;
	width: 100%;
	
	background-color: #212121;
	box-shadow: 0px 0px 2px 0px rgba(255,255,255,0.8);		
}

.checkBox
{
	position: absolute;
	width: 100px;
	height: 100%;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	
	background-image: url('../assets/images/white_circle.png');
	background-repeat: no-repeat;
	background-size: 25px auto;
	background-position: center right;
}

.checkBox.active
{
	background-image: url('../assets/images/check_circle.png');
}
	