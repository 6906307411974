body
{
	background-color: #000;
	
	color: #fff;
}

*
{
	box-sizing: border-box;
}

.UserPlaylists
{
	max-width: 95% !important;
	
	margin: 0 auto;
	padding: 0 1.5rem;
}

.UserPlaylists.from_popup .top-header
{
	display: none !important;
}

.playlist_popup_holder .UserPlaylists
{
	width: 800px;
}