.search_result_main
{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	padding: 20px;
	
	background-color: #000;
	overflow: auto;
	
	z-index: 9999;
}