.popups_holder
{
	display: none;
	
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	
	background-color: rgba(0,0,0, 0.7);
}

.popups_holder.active
{
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2000;
}

.pop_hold
{
	position: relative;
}

.new_song
{
	position: relative;
	padding: 5px 20px 20px 30px;
	border-radius: 10px;
	
	background-color: #2a2a2a;
}

.new_song_tigle
{
	padding: 10px 0px;
	
	border-bottom: 1px solid #000;
}

.new_song_body
{
	display: flex;
	padding: 30px 10px 10px 10px;
	
	border-top: 1px solid #676767;
}


.saved_song_body
{
	padding: 60px 10px 50px 10px;
}

.new_song_title
{
	align-self: center;
	margin-right: 15px;
}

.purple_input
{
	padding: 10px 10px;
	
	background-color: #38253f;
	border: 2px solid #bd0bff;
	border-radius: 5px; 
	
	color: #fff;
}

.purple_input.error
{
	border: 2px solid #a42525;
}

.closePopup
{
	position: absolute;
	top: 5px; 
	right: 5px;
	
	width: 25px;
	height: 25px;
	
	background-color: #7c1f9c;
	border-radius: 100%;
	background-image: url('../assets/images/iks.svg');
	
	background-size: 12px auto;
	background-repeat: no-repeat;
	background-position: center;
	
	cursor: pointer;
}

.saveBtn
{
	padding: 20px 0px;
	text-align: center;
}


.saveBtn div
{
	display: inline-block;
	
	padding: 8px 30px;
	
	background-color: #5c057b;
	color: #fff;
	border-radius: 15px;
	
	cursor: pointer;
}

