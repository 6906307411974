.playlist_popup_holder
{
	flex-direction: column;
}

.playlist_popup_holder .playlist_holder
{
	padding: 5px 15px;
	
	border-radius: 10px;
}

.playlist_popup_holder .closePopup
{
	right: -5px;
}

.playlist_popup_holder .playlist_main_title
{
	justify-content: center;
}

.playlist_popup_holder .saveBtn div
{
	padding: 10px 36px;
	
	font-size: 18px;
	
	cursor: pointer;
}


.playlist_popup_holder .song_main_holder
{
	padding: 5px 0px;
}

.playlist_popup_holder .playlist_holder .playlists_list
{
	border: none;
}

.playlist_popup_holder .songHold .playlists_list:nth-child(1)
{
	border-top: 1px solid #676767;
}

.playlist_popup_holder  .songHold
{
	padding-bottom: 20px;
	max-height: 300px;
	
	overflow: auto;
}


.playlist_popup_holder .s1
{
	width: 45%;
}

.playlist_popup_holder .s2
{
	width: 20%;
}

.playlist_popup_holder .s1:nth-child(3)
{
	width: 35%;
	padding-right: 40px;
}