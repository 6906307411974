.playlist_holder
{
	margin-top: 40px;
	
	padding: 5px 30px;
	
	background-color: #2a2a2a;
	border-radius: 20px;
}

.playlist_main_title
{
	display: flex;
}

.playlist_head
{
	position: relative;
	
	padding: 25px 0px;
	border-bottom: 1px solid #000;
}

.playlist_head a
{
	color: #fff;
	text-decoration: none;
}

.p_title
{
	align-self: center;
	
	font-size: 24px;
}

.new_playlist
{
	position: relative;
	
	width: 50px;
	height: 50px;
	margin-left: 40px;
	
	display: inline-block;
	border: 2px solid #BD00FF;
	border-radius: 10px;
	padding: 5px;
	
	background-image: url('../assets/images/plus.png');
	
	background-size: 25px auto;
	background-repeat: no-repeat;
	background-position: center;
	
	cursor: pointer;
}

.new_playlist input 
{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
	
	cursor: pointer;
}

::-webkit-file-upload-button { cursor:pointer; } 

.backBtn
{
	position: absolute;
	right: 0px;
	top: 50%;
	transform: translateY(-50%);
	
	display: inline-block;
	
	padding: 7px 25px;
	
	background: #BD00FF;
	border-radius: 5px;
	
	font-size: 17px;
	
	cursor: pointer;
}

.playlist_menu
{
	display: flex;
	
	padding: 20px 0px 20px 45px;
	
	border-top: 1px solid #676767;
	border-bottom: 1px solid #000;
}

.playlist_menu div
{
	font-size: 17px;
}

.playlist_menu .menu1
{
	width: 33.3%;
}

.playlist_menu .menu11
{
	width: 33.3%;
}


.playlist_menu .menu21
{
	width: 33.3%;
}

.playlist_menu .menu3
{
	width: 33.3%;
}

.playlist_menu .menu2
{
	width: 33.3%;
}

.playlists_list
{
	border-top: 1px solid #676767;
}

.playlists_list.empty_list
{
	padding: 30px 0px;
	
	text-align: center;
	font-size: 18px;
}


.main_loader.active
{
	display: flex !important;
}



